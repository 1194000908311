<template>
    <div>
        <footer class="mt-12 p-4 md:p-6 xl:p-8 dark:bg-gray-800 md:flex md:items-center md:justify-between">
            <div class="mb-4 xl:flex xl:items-center space-x-3 md:mb-0">
                <p class="mb-4 text-sm text-center text-gray-500 dark:text-gray-400 xl:mb-0">
                    &copy; {{ currentYear }} <a href="https://wetix.co/" class="hover:underline" target="_blank">WeTix
                        Ltd</a>. All
                    rights
                    reserved.
                </p>
                <ul class="flex justify-center items-center">
                    <li>
                        <NuxtLink href="https://status.wetix.run"
                            class="mr-6 text-gray-900 underline hover:no-underline dark:text-white">Status</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink href="https://wetix.page/weitx-footer-privacy-policy"
                            class="mr-6 text-gray-900 underline hover:no-underline dark:text-white">Privacy
                            Policy</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink href="https://wetix.page/wetix-footer-api"
                            class="mr-6 text-gray-900 underline hover:no-underline dark:text-white">API</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink href="https://wetix.events/contact"
                            class="text-gray-900 underline hover:no-underline dark:text-white">Contact</NuxtLink>
                    </li>
                </ul>
            </div>
            <div class="flex justify-center items-center space-x-3">

                <span @click="devMode = !devMode"
                    class="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-600">
                    <Icon name="heroicons:command-line" class="h-6 w-6" aria-hidden="true" />
                </span>

                <NavTenantSwitcher />

            </div>
        </footer>
    </div>
</template>

<script setup lang="ts">

const devMode = useCookie<Boolean>('wetix-dev-mode')

const currentYear = useDateFormat(useNow(), 'YYYY')


</script>

